import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from "yup";
// import { Form } from 'react-router-dom';
import FormikController from '../../components/formik/FormikController';
import { transactionDetailService } from '../../service/transactionEnqService';
import toastConfig from '../../components/toastTypes';
import moment from 'moment';
import MyContext from '../../hooks/MyContext';
import { useLocation } from 'react-router-dom';
import { currentSiteLanguage } from '../../multi-language/config';
// import TableToPDF from '../payment-collection/printReceipt/TableToPDF';
import PrintDocument from '../payment-collection/printReceipt/PrintDocument';
import { sp_bank_id } from '../../config/config';
import { useTracking } from 'react-tracking';
import { SessionId } from '../../utilities/SessionId';
import uniqueId from 'uniqid';


function TransactionEnq({ closeModalFn }) {
    // console.log("props", closeModalFn)
    const [txnData, setTxnData] = useState([])
    const [loader, setLoader] = useState(false)

    const { trackEvent } = useTracking()

    const closeModalAction = () => {
        // console.log("call",props)
        closeModalFn(false)

        trackEvent({
            message: "Use/Action/Check Payment Status - User Close the modal",
            ppp_id: "",
            session_id: SessionId()
        })
    }

    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())
    }, [location])




    const initialValues = {
        transaction_id: "",
        check_status_by: "transaction_id"
    };
    const validationSchema = Yup.object({
        transaction_id: Yup.string().max(30, context[siteLanguage]['transaction_id_length_exceed']).required(context[siteLanguage]['required']),
        check_status_by: Yup.string().nullable().required(context[siteLanguage]['required'])
    });


    const onSubmit = (input) => {
        // console.log(input)
        setLoader(true)
        setTxnData([])


        const postBody = {

            "uid": '',

            "pppid_fid": input?.check_status_by === 'ppp_id' ? input?.transaction_id : '',

            "transaction_id": input?.check_status_by === 'transaction_id' ? input?.transaction_id : '',
            bank_id: sp_bank_id

        }

        trackEvent({
            message: `Use/Action/Check Payment Status - Request For the enquiry ${JSON.stringify(postBody)}`,
            ppp_id: input?.transaction_id,
            session_id: SessionId()
        })


        transactionDetailService.transactionService(postBody).then(resp => {
            setLoader(false)
            // console.log(resp)
            if (resp?.data?.count === 0) {
                toastConfig.errorToast(context[siteLanguage]['no_data_found'])
            } else {
                setTxnData(resp?.data?.results)
            }

        }).catch(err => {
            toastConfig.errorToast(context[siteLanguage]['no_data_found']);
            setLoader(false)
        })
    }


    const txnOption = [
        { key: context[siteLanguage]['transaction_id'], value: "transaction_id" },
        { key: context[siteLanguage]['ppp_id'], value: "ppp_id" }
    ];

    let obj = {}
    let tempDataArr = []
    txnData?.map((data => {
        obj = {
            ppp_id: data?.pppid_fid,
            mobile_no_email: data?.payer_mobile,
            transaction_id: data?.gateway_trans_id,
            data_time: moment(data?.trans_init_date).format("DD-MM-YYYY HH:mm:ss"),
            name: data?.payer_name,
            amount: data?.base_amount,
            payment_bank_name: data?.pg_partner_bank,
            payment_mode: data?.trans_mode,
            client_trans_id: data?.client_trans_id,
            trans_status: data?.trans_status,
        }
        tempDataArr.push(obj)
    }))

    const handlerChange= (e, setFormikValue)=>{
        setFormikValue("check_status_by",e.target.value)
        setFormikValue("transaction_id","")
        setTxnData([])
    }

    const printClick = async () => {

        let tableContents = document.getElementById("print_docuement").innerHTML;
        let a = window.open("", "", "height=900, width=900");
        a.document.write(tableContents);
        a.document.close();
        await a.print();
    };


    return (
        <div id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal" style={{ display: 'block', background: 'rgba(0,0,0,0.6)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            {context[siteLanguage]['check_payment_status']}
                        </h1>
                        <button type="button" onClick={() => closeModalAction()} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {(formik) => (
                                        <Form >

                                            <div className="col-lg-12">
                                                <div className="d-flex">
                                                    <FormikController
                                                        control="radio"
                                                        className="form-check-input"
                                                        options={txnOption}
                                                        name="check_status_by"
                                                        onChange={(e)=>handlerChange(e,formik.setFieldValue)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="p-2">
                                                    <div className="col-lg-10 col-md-12 col-sm-12">
                                                        {/* {console.log(formik)} */}
                                                        <FormikController
                                                            control="input"
                                                            // label="Transaction ID  *"
                                                            lableClassName="font-weight-bold"
                                                            name="transaction_id"
                                                            placeholder={`${context[siteLanguage][formik.values.check_status_by]}`}
                                                            className="form-control"
                                                            displayError={true}
                                                        />
                                                    </div>
                                                    <div className="mt-3">

                                                        <button
                                                            className="btn btn-sm btn-primary-color mt-2"
                                                            type="submit"
                                                            disabled={loader}
                                                        >
                                                            {loader ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : context[siteLanguage]['view']}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>


                        <div className="container mt-3">
                            <div className="col text-center">{txnData?.length > 0 && <h5>{context[siteLanguage]['payment_receipt']}</h5>} </div>
                            {txnData?.map((data,i) => (
                                <div className="row justify-content-center" key={uniqueId()}>
                                    <hr />
                                    <div className="col-lg-12 col-sm-12 col-md-12">
                                        <table className="table table-bordered mx-auto">
                                            <tbody>
                                                <tr>
                                                    <th>{context[siteLanguage]['ppp_id']}</th>
                                                    <td>{data?.pppid_fid}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['mobile_no_email']}</th>
                                                    <td>{data?.payer_mobile} </td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['transaction_id']}</th>
                                                    <td>{data?.gateway_trans_id}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <th>{context[siteLanguage]['reference_number']}</th>
                                                    <td>{data?.client_trans_id}</td>
                                                </tr>

                                                <tr>
                                                    <th>{context[siteLanguage]['data_time']}</th>
                                                    <td>{moment(data?.trans_init_date).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['name']}</th>
                                                    <td>{data?.payer_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['amount']}</th>
                                                    <td>₹ {data?.base_amount}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['payment_status']}</th>
                                                    <td>{data?.trans_status}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['payment_bank_name']}</th>
                                                    <td>{data?.pg_partner_bank}</td>
                                                </tr>
                                                <tr>
                                                    <th>{context[siteLanguage]['payment_mode']}</th>
                                                    <td>{data?.trans_mode}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}

                            <PrintDocument data={tempDataArr} />
                        </div>
                        {/* <TableToPDF /> */}
                        <div className="modal-footer">
                            {txnData?.length > 0 &&
                                <button type="button" className="btn btn-primary-color btn-sm" data-bs-dismiss="modal" onClick={() => printClick()}>{context[siteLanguage]['print']}</button>}

                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={() => closeModalAction()}>{context[siteLanguage]['close']}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionEnq