import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
// axiosSession

import { API_URL, sp_bank_id } from '../../config/config';
import moment from 'moment';
import MyContext from '../../hooks/MyContext';
import { currentSiteLanguage } from '../../multi-language/config';
import toastConfig from '../../components/toastTypes';
import PrintDocument from './printReceipt/PrintDocument';
import { useTracking } from 'react-tracking';
import { SessionId } from '../../utilities/SessionId';
import { axiosSession as axios } from '../../utilities/axiosInstance';


// import PrintDocument from '../../components/PrintDocument';


function PaymentDetail() {
  const [data, setData] = useState({})
  const [dataArr, setDataArr] = useState([])
  const [dataLoader, setDataLoader] = useState(false)
  const navigate = useNavigate()

  const { spTransactionId } = useParams();
  const { trackEvent } = useTracking()

  useEffect(() => {



    const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"))
    if (isLoggedIn === null) {
      navigate("/")
    }

    const postData = {
      "pppid_fid": "",
      "transaction_id": "",
      "client_transaction_id": spTransactionId,
      "bank_id": sp_bank_id
    }
    setDataLoader(true)
    axios.post(API_URL.transaction_enquiry, postData).then(resp => {
      setDataLoader(false)



      // console.log(resp?.data?.results[0])

      let tempDataArr = []
      // console.log("dataArr",dataArr)
      resp?.data?.results?.map((data => (
        tempDataArr.push({
          ppp_id: data?.pppid_fid,
          mobile_no_email: data?.payer_mobile,
          transaction_id: data?.gateway_trans_id,
          data_time: moment(data?.trans_init_date).format("DD-MM-YYYY HH:mm:ss"),
          name: data?.payer_name,
          amount: data?.base_amount,
          payment_bank_name: data?.pg_partner_bank,
          payment_mode: data?.trans_mode,
          client_trans_id: data?.client_trans_id,
          trans_status: data?.trans_status,
        })
      )))
      setDataArr(tempDataArr)

      setData(resp?.data?.results[0])


    }).catch(err => {
      setDataLoader(false)
      toastConfig.errorToast("#101 - Something went wrong!")
    })

    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("cta_payment");




    trackEvent({
      message: `User/Action/Payment Confirmation Page - Retrive the data - ${JSON.stringify(postData)}`,
      ppp_id: `Not capture`,
      session_id: SessionId()
    })
  }, [])



  // site language detecter
  const context = useContext(MyContext);
  const [siteLanguage, setSiteLanguage] = useState("en");
  const location = useLocation()
  useEffect(() => {
    setSiteLanguage(currentSiteLanguage())
  }, [location])






  const printClick = async () => {
    let tableContents = document.getElementById("print_docuement").innerHTML;
    let a = window.open("", "", "height=900, width=900");
    a.document.write(tableContents);
    a.document.close();
    await a.print();

    trackEvent({
      message: `User/Action/Payment Confirmation Page -Click Print`,
      ppp_id: `ppp_id-${data?.pppid_fid}`,
      session_id: SessionId()
    })
  };


  const paymentStatus =
  {
    "INITIATED": context[siteLanguage]['initiated'],
    "REFUNDED": context[siteLanguage]['refunded'],
    "ABORTED": context[siteLanguage]['aborted'],
    "FAILED": context[siteLanguage]['failed'],
    "SUCCESS": context[siteLanguage]['successful'],
  }

  // console.log(paymentStatus["SUCCESS"])

  return (
    <div className="row background-image-color">
      <Navbar enableLogo={true} />
      {!dataLoader ?
        <div className="row">
          <div className="col-lg-6 justify-content-start align-items-center">
            <div className=" px-4 ">
              <div className="text-center mb-2">

                <h2 className="text-primary-color">
                  {context[siteLanguage]['payment']} {paymentStatus[data?.trans_status]}!</h2>
                <p className="m-0">{context[siteLanguage]['your_payment_is']} {paymentStatus[data?.trans_status]}. {context[siteLanguage]['please_find_the_detail']}</p>
              </div>

              <div className="p-0 align-items-end">

                <table className="table table-bordered mx-auto">
                  <tbody>
                    <tr>
                      <th>{context[siteLanguage]['ppp_id']}</th>
                      <td>{data?.pppid_fid}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['mobile_no_email']}</th>
                      <td>{data?.payer_mobile}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['transaction_id']}</th>
                      <td>{data?.gateway_trans_id}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['reference_number']}</th>
                      <td>{data?.client_trans_id}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['data_time']}</th>
                      <td>{moment(data?.trans_init_date).format("DD-MM-YYYY HH:mm:ss")}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['name']}</th>
                      <td>{data?.payer_name}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['amount']}</th>
                      <td>₹ {data?.base_amount}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['payment_status']}</th>
                      <td>{data?.trans_status}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['payment_bank_name']}</th>
                      <td>{data?.pg_partner_bank}</td>
                    </tr>
                    <tr>
                      <th>{context[siteLanguage]['payment_mode']}</th>
                      <td>{data?.trans_mode}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
            <div className="text-center">
              {dataArr?.length > 0 && <button className="btn btn-sm btn-primary-color m-2" onClick={() => printClick()} disabled={data?.pppid_fid ? false : true}> {context[siteLanguage]['print']}</button>}
              <button className="btn btn-sm btn-secondary m-2" onClick={() => {
                trackEvent({
                  message: `User/Action/Payment Confirmation Page -Click to Exit `,
                  ppp_id: `ppp_id-${data?.pppid_fid}`,
                  session_id: SessionId()
                })
                navigate('/')
              }} > {context[siteLanguage]['exit']}</button>
            </div>
            <PrintDocument data={dataArr} />

          </div>
        </div>
        :
        <p className="text-center"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>}
    </div>
  )
}

export default PaymentDetail