import React, { useContext, useEffect, useState } from 'react'
import logohr from "../../../assest/images/hr-logo-removebg-preview.png"
import logopm from "../../../assest/images/pmjay-logo-removebg-preview.png"
import ayushLogo from "../../../assest/images/ayushman-logo-removebg-preview.png"
// import language from "../../../assest/images/lang-icon.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import DropDown from '../../DropDown'
import { currentSiteLanguage, languageAvailabe } from '../../../multi-language/config.js'
import MyContext from '../../../hooks/MyContext'
import classes from "../navbar.module.css"
import { useNavigation } from '../../../hooks/useNavigation'
import AuthButton from '../AuthButton';
import { useSelector } from 'react-redux';



function NavbarWithLogo(props) {
    // console.log("NavbarWithLogo")
    const navigate = useNavigate();
    const location = useLocation();
    const { navigateWithQuery } = useNavigation();
    const { loginReducer } = useSelector(state => state)

    const searchParam = window.location.search.slice(1)
    const queryString = new URLSearchParams(searchParam?.toString());
    const queryStringData = Object.fromEntries(queryString.entries());

    const currentLanguage = queryStringData?.lang ?? "hi"
    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    // const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())
    }, [location])



    const languageHandler = (lang) => {
        // const queryStringUrl = window.location.search;
        navigate(`${location?.pathname}?lang=${lang}`)
        // navigateWithQuery(location?.pathname, `lang=${lang}`)
    }


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Function to update the state with current window width and height
        const updateViewportDimensions = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        // Event listener to update dimensions on window resize
        window.addEventListener('resize', updateViewportDimensions);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', updateViewportDimensions);
        };
    }, []); // Empty dependency array to run the effect only once on mount

    // console.log("viewportWidth",viewportWidth)
    // console.log("currentLanguage", currentLanguage)

    return (
        <header className="">
            <div className="container-fluid p-2">
                <div className="d-lg-flex d-md-flex justify-content-between">
                    <div className="logo-list">
                        <div className="nav col-12 col-lg-auto me-lg-auto  justify-content-between mb-md-0 text-center">
                            <p ><img src={logohr} alt="logo" className={`mx-2 mb-0 ${classes.logo_hr}`} /></p>
                            <p ><img src={logopm} alt="logo" className={`mx-2 mb-0 ${classes.logo_pmjay}`} /></p>
                            <p><img src={ayushLogo} alt="logo" className={`mx-2 mb-0 ${classes.logo_ayush}`} /></p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <DropDown handlerChange={languageHandler} siteLanguage={languageAvailabe} currentLang={currentLanguage} />
                        {loginReducer?.isAuthValid && <AuthButton authData={loginReducer} />}
                    </div>
                </div>
            </div>
        </header>

    )
}

export default NavbarWithLogo