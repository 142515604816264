import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigation() {
    const location = useLocation();
    const navigate = useNavigate();

    const mergeQueries = (newQueryParams) => {
        const currentQuery = new URLSearchParams(location.search);
        const newQuery = new URLSearchParams(newQueryParams);

        for (let [key, value] of newQuery.entries()) {
            currentQuery.set(key, value);
        }

        return currentQuery;
    };

    const navigateWithQuery = (to, newQueryParams) => {
        const mergedQuery = mergeQueries(newQueryParams);

        navigate(to + '?' + mergedQuery.toString());
    };

    return {
        navigateWithQuery,
    };
}
