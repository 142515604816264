import React from "react"
import { Field, ErrorMessage } from "formik"
import classes from "./formik.module.css"


// id = value
// logopath = key

function RadioButtonImage(props) {
  // console.log(props)
  const { label, name, options, ...rest } = props
  return (
    <React.Fragment>
      <Field name={name}>
        {formik => {
          const { field } = formik
          {/* console.log("formik",formik?.field?.value) */}
          
          return options.map(option => {
            return (
              <div key={option.id} className="m-2">
              {/* {console.log("optionvalue",option)} */}
                <label htmlFor={option.value} className="form-check-label">
                {/* {option.key} */}
                <input
                  type="radio"
                  id={option.id}
                  {...field}
                  {...rest}
                  value={option.id}
                  checked={field?.value?.toString() === option?.value?.toString()}
                />
                <img src={option?.logo_path} width={100} alt="bank-logo" className={field?.value?.toString() === option?.id?.toString() ? classes.image_border : classes.border_none }  />
                </label>
              </div>
            )
          })
        }}
      </Field>
      <p className="text-danger"><ErrorMessage name={name} /></p>
    </React.Fragment>
  )
}

export default RadioButtonImage