import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../components/card/Card'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import FormikController from '../../components/formik/FormikController'
import * as Yup from 'yup';
import { clearOTPResponse, clearVerificationUID, verificationOTP, verificationUID } from '../../slice/verifyDetails/verifyDetailSlice'
import { currentSiteLanguage } from '../../multi-language/config'
import MyContext from '../../hooks/MyContext'
import Navbar from '../../components/navbar/Navbar'
import { SessionId } from '../../utilities/SessionId'
import { useTracking } from 'react-tracking'
import { Regex } from '../../components/formik/ValidationRegex'
import { APP_ENV } from '../../config/config'



function VerifyDetails() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { trackEvent } = useTracking()
    const formikRef = useRef();

    const queryStringUrl = window.location.search;
    const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"))
    if (isLoggedIn) {
        navigate(`/preview-details/${queryStringUrl}`)
    }

    const [pppInputEnable, setPPPInputEnable] = useState(true)
    // const [resendLinkEnable, setResendLinkEnable] = useState(false)
    const [formOneData, setFromOneData] = useState({})


    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())
    }, [location])


    const { home, verify } = useSelector(state => state)
    const { verify_UIDid, verify_otp } = verify
    const { data } = home

    useEffect(() => {
        if (data?.isAcceptUndertaking === false) {
            navigate(`/${queryStringUrl}`)
        }
    }, [data])






    const validationSchemaFirst = Yup.object().shape({
        forgot_pppid: Yup.bool(),


        ppp_id: Yup.string().when('forgot_pppid', {
            is: false,
            then: () =>
                Yup.string().required(context[siteLanguage]["please_enter_ppp_id"]).length(8, context[siteLanguage]['enter_valid_8_digit_ppp']),
        }),
        uid: Yup.string().when('forgot_pppid', {
            is: true,
            then: () =>
                Yup.string().required(context[siteLanguage]["please_enter_uid"])
                    .max(14, context[siteLanguage]["invalid_uid"])
                    .min(4, context[siteLanguage]["required"])
                    .required(context[siteLanguage]["uid_required"]),
        }),

    });



    const initialValuesFirst = {
        ppp_id: "",
        uid: "",
        forgot_pppid: false,
        orignalInput: ""
    }



    const initialValuesOtp = {
        otp_digit: "",
        txnToken: verify?.verificationPPPID?.respData?.txn,
        family_id: verify?.verificationPPPID?.respData?.result?.family_id
    }


    const validationSchemaOtp = Yup.object({
        otp_digit: Yup.string()
            .matches(Regex.digit, context[siteLanguage]["enter_the_otp"])
            .min(APP_ENV === 'dev' ? 6 : 4, APP_ENV === 'dev' ? "Please enter 6 digit OTP" : "Please enter 4 digit OTP")
            .max(APP_ENV === 'dev' ? 6 : 4, APP_ENV === 'dev' ? "Please enter 6 digit OTP" : "Please enter 4 digit OTP")
            .required(context[siteLanguage]["enter_the_otp"])
    })


    const handleChange = (event, setFormikFieldVal) => {
        const inputValue = event.target.value;
        setFormikFieldVal("orignalInput", inputValue);
        setFormikFieldVal("uid", inputValue);
    };

    const maskedInput = (e, setFormikFieldVal) => {
        const inputValueM = e.target.value;
        if (inputValueM?.length > 11) {
            const maskedDigit = "XXXX-XXXX-" + '' + inputValueM.slice(-4)
            setFormikFieldVal("uid", maskedDigit);
        }

    }

    // const initialTime = 12; // 10 minutes in seconds
    const [timeRemaining, setTimeRemaining] = useState(0);

    useEffect(() => {
        // console.log("timeRemaining",timeRemaining)
        if (timeRemaining > 0) {
            // setResendLinkEnable(false)
            const timer = setInterval(() => {
                setTimeRemaining(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeRemaining]);

    const formatTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}m : ${secondsLeft < 10 ? '0' : ''}${secondsLeft}s`;
    };




    const verifyHandler = (value) => {

        setTimeRemaining(120)
        setFromOneData(value)
        const postDataPPPverification = {
            uid: value?.orignalInput,
            by_fid: !value?.forgot_pppid,
            family_id: value?.ppp_id
        }


        dispatch(verificationUID(postDataPPPverification))
        dispatch(clearOTPResponse())

        trackEvent({
            message: `User/Action/ppp-verification/Verify PPPID/UID Data - ${JSON.stringify(postDataPPPverification)}`,
            ppp_id: "",
            session_id: SessionId()
        })


    }



    useEffect(() => {
        if (verify_otp?.isValid === true) {
            navigate(`/preview-details/${queryStringUrl}`)
        }

    }, [verify_otp])





    const otpVerifyHandler = (value) => {
        const otpPostData = {
            family_id: verify_UIDid?.response?.pppid_fid,
            otp: value.otp_digit,
            txn: verify_UIDid?.response?.result?.txn,
        }
        dispatch(verificationOTP(otpPostData))
        trackEvent({
            message: `User/Action/ppp-verification/OTP Submit - ${JSON.stringify(otpPostData)}`,
            ppp_id: verify_UIDid?.response?.pppid_fid,
            session_id: SessionId()
        })

    }

    const forgotPPPID = (setFieldValueFormik, isEnable) => {

        trackEvent({
            message: "User/Action/ppp-verification/Forgot PPP ID",
            ppp_id: "",
            session_id: SessionId()
        })

        setPPPInputEnable(!isEnable)
        setFieldValueFormik("forgot_pppid", isEnable)
        setFieldValueFormik("ppp_id", "")
        if (!isEnable) {
            formikRef.current?.resetForm()
        }

        dispatch(clearVerificationUID())
    }

    const backButtonHandler = (val) => {
        if (val === true) {
            navigate("/")
        } else {
            setPPPInputEnable(true)
            formikRef.current?.resetForm()
            dispatch(clearVerificationUID())
        }

    }

    const configCard = {
        enableIcon: true,
        backHandler: (e) => { backButtonHandler(e) },
        component: pppInputEnable
    }

    const verify_UIDidErrorMsg = (verify_UIDid?.response?.result?.message || verify_UIDid?.response?.message)
    const cardHeading = (
        <h5 className="text-center text-primary-color">{context[siteLanguage]["enter_details"]}</h5>
    )



    const cardBody = (
        <React.Fragment>
            <Formik initialValues={initialValuesFirst} validationSchema={validationSchemaFirst} onSubmit={(val) => verifyHandler(val)} innerRef={formikRef}>
                {(formik) => (
                    <Form>
                        {/* {console.log(formik)} */}

                        {pppInputEnable &&
                            <div>
                                <label htmlFor="exampleInputEmail1" className="form-label">{context[siteLanguage]['enter_the_pppid_or_aadhar_num']}</label>
                                <div className="mb-1">
                                    <FormikController
                                        control="input"
                                        name="ppp_id"
                                        className="form-control"
                                        placeholder={context[siteLanguage]['please_enter_ppp_id']}
                                        disabled={verify_UIDid?.isValid}
                                        displayError={true}
                                    />
                                    {verify_UIDid?.isValid && <p className="text-success">{context[siteLanguage]['verify']}</p>}
                                </div>
                                <p className="link-style form-text m-0 text-primary link-underline-primary cursor_pointer font-12"
                                    onClick={() => forgotPPPID(formik.setFieldValue, pppInputEnable)}

                                >{context[siteLanguage]["i_forgot_my_ppp"]}</p>
                            </div>

                        }
                        {!pppInputEnable &&
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">{context[siteLanguage]["enter_uid"]}</label>
                                <FormikController
                                    control="input"
                                    name="uid"
                                    className="form-control"
                                    placeholder="XXXX-XXXX-4444"
                                    disabled={verify_UIDid?.isValid}
                                    displayError={true}
                                    onChange={(e) => handleChange(e, formik.setFieldValue)}
                                    onBlur={(e) => { maskedInput(e, formik.setFieldValue) }}
                                />
                            </div>
                        }

                        <div id="emailHelp" className="form-text">{context[siteLanguage]["we_will_send_you_a"]}</div>

                        {verify_UIDid?.IsError && <p className="m-0 text-danger">{verify_UIDidErrorMsg?.toString()}</p>}
                        {verify_UIDid?.isValid !== true &&
                            <div className="text-center mt-5">
                                <button className="btn btn-sm btn-primary-color" disabled={verify_UIDid?.loader} type="submit">
                                    {verify_UIDid?.loader ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        :
                                        context[siteLanguage]['verify']}
                                </button>
                            </div>}
                    </Form>
                )}
            </Formik>




            {verify_UIDid?.isValid &&
                <Formik initialValues={initialValuesOtp} validationSchema={validationSchemaOtp} onSubmit={otpVerifyHandler}>
                    <Form>
                        <div className="d-flex-inline justify-content-center text-center mt-5 d-fle">
                            <h5 className="text-primary-color">{context[siteLanguage]["otp_verification"]}</h5>
                            <p className="text-danger">{verify_UIDid?.response?.result?.message}</p>
                            <div className="mb-3  text-center pl-4 pr-4">
                                <FormikController
                                    control="input"
                                    name="otp_digit"
                                    className="form-control"
                                    placeholder={context[siteLanguage]["enter_otp"]}
                                    displayError={true}
                                />
                                <div className="form-text">
                                    {context[siteLanguage]["do_not_recevie_the_otp"]}
                                    &nbsp;

                                    {timeRemaining > 0 ? context[siteLanguage]["resend"] :
                                        <button tabIndex="-1" aria-disabled="true" className="cursor_pointer disabled link-style" onClick={() => verifyHandler(formOneData)}>{context[siteLanguage]["resend"]} </button>}
                                    &nbsp;
                                    {timeRemaining > 0 && formatTime(timeRemaining)}

                                </div>
                                {verify_otp?.isError && <p className="m-0 text-danger">{verify_otp?.response?.message?.result?.message}</p>}

                                <button className="btn btn-sm btn-primary-color mt-2" disabled={verify_otp?.loader} type="submit">
                                    {verify_otp?.loader ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : context[siteLanguage]['submit']}</button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            }
        </React.Fragment>)
    const cardFooter = (<></>)

    return (
        <div className="container-fluid">
            <Navbar enableLogo={true} forceDisable={false} />
            <div className="row">
                <div className="col-lg-6 justify-content-center align-items-center px-4">
                    <Card heading={cardHeading} body={cardBody} footer={cardFooter} configCard={configCard} />
                </div>
            </div>
        </div>
    )
}

export default VerifyDetails