// import axios from "axios"

import { API_URL } from "../config/config"
import { currentSiteLanguage } from "../multi-language/config";
import { axiosSession as axios } from "../utilities/axiosInstance";
const lang = currentSiteLanguage();

const configAxios = {
    headers: {
        "Accept-Language": lang.toUpperCase()
    }
}




const transactionService = (obj) => {
    return axios.post(API_URL.transaction_enquiry, obj, configAxios);
}

const checkPaymentStatus = (obj) => {
    return axios.post(API_URL.check_payment, obj, configAxios);
}


export const transactionDetailService = {
    transactionService,
    checkPaymentStatus
}



