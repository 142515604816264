// import axios from "axios"

import  axios from "axios"
import { API_URL } from "../config/config"
import { currentSiteLanguage } from "../multi-language/config";
const lang = currentSiteLanguage();

const configAxios = {
    headers: {
        "Accept-Language": lang.toUpperCase()
    }
}


export const login = (obj)=>{
    return axios.post(API_URL.login, obj, configAxios)
} 

export const loginVerify = (obj)=>{
    return axios.post(API_URL.login_verify, obj, configAxios)
}

export const getDataBySession = (obj)=>{
    return axios.post(API_URL.get_data_by_session, obj, configAxios)
}