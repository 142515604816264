const appEnv = 'prod' // prod / dev
export const TIMEOUT = 600
let url, csc_connect_url, csc_service_frontend_url = ""

if (appEnv === 'prod') {
    url = "https://services.chirayuayushmanharyana.in";
    csc_connect_url = "https://apiconnectcsc.sabpaisa.in"
    csc_service_frontend_url = "https://connectcsc.sabpaisa.in"
} else {

    url = "https://stage-chirayu.sabpaisa.in";
    csc_connect_url = "https://stage-csc-connect.sabpaisa.in"
    csc_service_frontend_url = "https://stage-csc.sabpaisa.in"
    // url="http://192.168.34.91:8000";
}

export const APP_ENV = appEnv

// staging - canara bank
// export const PG_CRED = {
//     clientCode: "LPSD1",
//     username: "Abh789@sp",
//     password: "P8c3WQ7ei",
//     authKey: "QVMtR1JBUy1QUk9E",
//     authIV: "1234567890123456",
// }

export const PG_CRED = {
    clientCode: "ABHCS",
    username: "kapilsharma.dhs_11125",
    password: "ABHCS_SP11125",
    authKey: "PJiF7dGJOYLMJzFp",
    authIV: "TG5zlMhUX1LbfhIG",
}



export const sp_bank_id = "3"

export const API_URL = {
    verify_ppp_id: `${url}/api/ppp/verify-ppp-id/`,
    family_id_by_uid: `${url}/api/ppp/family-id-by-uid/`,
    verify_otp: `${url}/api/ppp/verify-ppp-otp/`,


    save_transaction: `${url}/api/save-transaction/`,
    update_transaction: `${url}/api/update-transaction/`,
    get_transaction_amount: `${url}/api/ppp/get-ppp-trans-amount/`,
    get_data_by_txn_id: `${url}/api/admin/transactions/get-by-gateway-trans-id/`,
    bank_list: `${url}/api/bank/get-bank-by-name/`,
    check_eligibility: `${url}/api/ppp/check-eligibility/`,
    transaction_enquiry: `${url}/api/transaction-enquiry/`,

    check_payment: `${url}/api/check-payment/`,

    login: `${url}/api/user/login/`,
    login_verify: `${url}/api/user/login-verify/`,
    log_save: `${url}/api/log/`,
    csc_payment_form: `${url}/api/csc-payment/`,

    get_data_by_session: `${csc_connect_url}/api/csc-auth/get-by-session/`,

    csc_connect_frontend_url: csc_service_frontend_url
    // https://stage-csc-connect.sabpaisa.in/api/csc-auth/get-by-session/

};

