import { createSlice } from '@reduxjs/toolkit';
// import { fetchPostsAPI } from '../../services/postsService';

// export const fetchPosts = createAsyncThunk(
//   'posts/fetchPosts',
//   async () => {
//     const response = await fetchPostsAPI();
//     return response.data;
//   }
// );

const initialState = {
    data: {
        isAcceptUndertaking:false
    }, 
    loading: false, 
    error: null
}

const homeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    // Additional synchronous actions can be defined here if needed
    undertakingForm: (state, action)=>{
        state.data.isAcceptUndertaking = true
    }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchPosts.pending, (state) => {
//         state.loading = true;
//         state.error = null; // Reset the error state when starting the API call
//       })
//       .addCase(fetchPosts.fulfilled, (state, action) => {
//         state.data = action.payload;
//         state.loading = false;
//         state.error = null;
//       })
//       .addCase(fetchPosts.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.error.message;
//       });
//   },
});

export const { undertakingForm } = homeSlice.actions;
export default homeSlice.reducer;