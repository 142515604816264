import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (

    <div className="container">
      <div className="row">
        <div className="col-12 d-flex-inline text-center">
          <h3>404 - Page Not Found</h3>
          <Link to="/" >Back To The Main Page</Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound