import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from "./checkeligible.module.css"
import MyContext from '../../../hooks/MyContext'
import { currentSiteLanguage } from '../../../multi-language/config'
import { useDispatch } from 'react-redux'
import { clearEligibilityState, clearSessionData } from '../../../slice/verifyDetails/verifyDetailSlice'
import { useTracking } from 'react-tracking'
import { SessionId } from '../../../utilities/SessionId'

function CheckEligible({ eligibility, fnSubmitHandler, buttonText, closeModalFn, paymentStatusResp, familyId }) {
    // eligibility?.respData?.eligibility_code
    // console.log("eligibility",eligibility)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { trackEvent } = useTracking()
    const makePayment = () => {
        trackEvent({
            message: "User/Action/Check eligiblity/Click -proceed for the payment",
            ppp_id: familyId,
            session_id: SessionId()
        })
        fnSubmitHandler()
        sessionStorage.setItem("cta_payment",0)
        dispatch(clearEligibilityState())
    }

    const closeModal = () => {
        // console.log("call close fn")
        trackEvent({
            message: "User/Action/Check eligiblity/User Exit",
            ppp_id: familyId,
            session_id: SessionId()
        })
        closeModalFn(null)
        dispatch(clearEligibilityState())
        dispatch(clearSessionData())
        navigate('/')

    }


    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())
    }, [location])



    return (
        <div className="modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', background: 'rgba(0,0,0,0.8)' }}>
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className={`modal-header ${eligibility?.respData?.is_eligible ? classes.eligible_valid : classes.eligible_novalid}`}>
                        <button type="button" onClick={() => closeModal()} className="btn-close btn-sm py-0" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="text-center text-bold">
                            <h4>
                                {eligibility?.respData?.message}
                            </h4>
                        </div>
                        
                    </div>
                    <div className="modal-footer p-0 justify-content-center">
                        <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal" onClick={() => closeModal()}>
                            {context[siteLanguage]['exit']}
                        </button>

                        {(eligibility?.respData?.payment_required && paymentStatusResp===false) && <button className="btn btn-sm btn-primary-color" onClick={() => makePayment()}>{buttonText}</button>}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CheckEligible