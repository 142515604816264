import { configureStore } from '@reduxjs/toolkit';
import homeReducer from "./slice/home/homeSlice.js"
import verifyDetailReducer from "./slice/verifyDetails/verifyDetailSlice.js"
import loginReducer from "./slice/login/loginSlice.js"
 
const store = configureStore({
  reducer: {
    home: homeReducer,
    verify: verifyDetailReducer,
    loginReducer:loginReducer
  },
});

export default store;