
import { API_URL } from "../config/config"
import { currentSiteLanguage } from "../multi-language/config";
import { axiosSession as axios } from "../utilities/axiosInstance"
const lang = currentSiteLanguage();

const configAxios = {
    headers: {
        "Accept-Language": lang.toUpperCase()
    }
}

// console.log("axios",axios)
export const fetchBankList = (obj) => {
    return axios.post(API_URL.bank_list, obj, configAxios)
}

