import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { verfiDetailServices } from '../../service/verifyDetailService';

const initialState = {

    pppDetailVerifyBy: {
        verifyBy: "pppid" //pppid / uid
    },
    verify_pppid: {
        response: {},
        isValid: null,
        IsError: null,
        loader: false,
        displayMsg: false
    },

    verify_UIDid: {
        response: {},
        isValid: null,
        IsError: null,
        loader: false,
        displayMsg: false
    },

    verify_otp: {
        response: {},
        isValid: null,
        IsError: null
    },
    ppp_details: {
        response: {},
        isValid: null,
        IsError: null,
        loader: false
    },
    paymentPostData: {},
    eligibility: {
        respData: {},
        isError: null,
        isValid: null,
        message: ""
    },
    enableTxnFeeCharge: false,

    auth: {
        authenticate: false,
        authData: {

        }
    }

}

export const verificationPPPID = createAsyncThunk(
    'verification/verificationPPPID',
    async (obj, thunkAPI) => {
        try {
            const response = await verfiDetailServices.verifyPPPIDService(obj);
            return response.data;
        } catch (error) {

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            const err = { message: message, status_code: error?.response?.data?.status_code }

            return thunkAPI.rejectWithValue(err);
        }
    }


);

export const verificationUID = createAsyncThunk(
    'verification/verificationUID',
    async (obj, thunkAPI) => {
        try {
            const response = await verfiDetailServices.verifyUIDIDService(obj);
            return response.data;
        } catch (error) {

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            const err = { message: message, status_code: error?.response?.data?.status_code }

            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const verificationOTP = createAsyncThunk(
    'verification/verificationOTP',
    async (obj, thunkAPI) => {
        try {
            const response = await verfiDetailServices.verifyOtpService(obj);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message || error.response.message.result.message
            error.toString();



            const err = { message: message, status_code: error?.response?.data?.status_code }

            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const checkEligibilityIncome = createAsyncThunk(
    'verification/checkEligibilityIncome',
    async (obj, thunkAPI) => {
        // console.log(obj)
        try {
            const response = await verfiDetailServices.checkEligibility(obj);
            return response.data;
        } catch (error) {

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            const err = { message: message, status_code: error?.response?.data?.status_code }

            return thunkAPI.rejectWithValue(err);
        }
    }
);

const verifyDetailsSlice = createSlice({
    name: 'verifyDetailsSlice',
    initialState: initialState,
    reducers: {
        // Additional synchronous actions can be defined here if needed
        paymentPostData: (state, action) => {
            // console.log("action",action.payload)
            state.paymentPostData = action.payload
        },

        clearOtpLoader: (state, action) => {
            state.verificationPPPID.optLoader = null
            state.verificationPPPID.error = false
        },

        clearOtpResp: (state, action) => {
            state.optResp.isValid = null
            state.verificationOTP = {}
        },

        clearVerificationPPPID: (state, action) => {
        },

        clearVerificationUID: (state, action) => {
            state.verify_UIDid.response = {}
            state.verify_UIDid.isValid = null
            state.verify_UIDid.IsError = null
            state.verify_UIDid.loader = false
            state.verify_UIDid.displayMsg = false
        },

        clearOTPResponse:(state)=>{
            // verify_otp: {
            //     response: {},
            //     isValid: null,
            //     IsError: null
            // },
            // ppp_details: {
            //     response: {},
            //     isValid: null,
            //     IsError: null,
            //     loader: false
            // },
            state.verify_otp.response={}
            state.verify_otp.isValid=null
            state.verify_otp.IsError=null

            state.ppp_details.response={}
            state.ppp_details.isValid=null
            state.ppp_details.IsError=null
            // state.ppp_details.loader=null
        },

        clearEligibilityState: (state) => {
            state.eligibility.isError = null
            state.eligibility.isValid = null
            state.eligibility.message = ""
            state.eligibility.respData = {}
        },

        enableTxnFeeTable: (state, action) => {
            state.enableTxnFeeCharge = action.payload
        },

        clearSessionData: (state, action) => {
            // isLoggedIn:"true"
            sessionStorage.removeItem("isLoggedIn");
            
            // sessionStorage.removeItem("isLoggedIn");

            state.pppDetailVerifyBy = {
                verifyBy: "pppid" //pppid / uid
            }
            state.verify_pppid = {
                response: {},
                isValid: null,
                IsError: null,
                loader: false,
                displayMsg: false
            }

            state.verify_UIDid = {
                response: {},
                isValid: null,
                IsError: null,
                loader: false,
                displayMsg: false
            }

            state.verify_otp = {
                response: {},
                isValid: null,
                IsError: null
            }
            state.ppp_details = {
                response: {},
                isValid: null,
                IsError: null
            }

            state.paymentPostData = {}
            state.eligibility = {
                respData: {},
                isError: null,
                isValid: null,
                message: ""
            }
            state.enableTxnFeeCharge = false

            state.auth = {
                authenticate: false,
                authData: {

                }
            }

        }



    },
    extraReducers: {
        [verificationPPPID.pending]: (state) => {
            // state.verificationPPPID.optLoader = null;
            // state.verificationPPPID.error = false;
            // state.verificationPPPID.respData = {};

            // update the verify state
            state.verify_pppid.response = {}
            state.verify_pppid.isValid = null
            state.verify_pppid.IsError = null
            state.verify_pppid.loader = false
            state.verify_pppid.displayMsg = false
            state.pppDetailVerifyBy.verifyBy = "pppid"

        },
        [verificationPPPID.fulfilled]: (state, action) => {

            // new state
            state.verify_pppid.response = action.payload


            if (action.payload.status === true) {
                state.verify_pppid.isValid = true
            } else {
                state.verify_pppid.displayMsg = true
                state.verify_pppid.IsError = true
            }
            state.verify_pppid.loader = false

        },
        [verificationPPPID.rejected]: (state, action) => {
            // state.verificationPPPID.optLoader = false;
            // state.verificationPPPID.error = true;

            // console.log(action.error.message)

            state.verify_pppid.response = action.payload
            state.verify_pppid.isValid = null
            state.verify_pppid.IsError = true
            state.verify_pppid.loader = false
            state.verify_pppid.displayMsg = true
            // state.loading = false;
            // state.error = action.error.message;
        },
        [verificationUID.pending]: (state) => {
            state.pppDetailVerifyBy.verifyBy = "uid"
            // state.verificationPPPID.optLoader = null;
            // state.verificationPPPID.error = false;
            // state.verificationPPPID.respData = {};

            // update the verify state
            // state.verify_pppid.response = {}
            // state.verify_pppid.isValid = null
            // state.verify_pppid.IsError = null
            // state.verify_pppid.loader = false
            // state.verify_pppid.displayMsg = false
            // state.pppDetailVerifyBy.verifyBy="pppid"
            state.verify_UIDid.response = {}
            state.verify_UIDid.isValid = null
            state.verify_UIDid.IsError = null
            state.verify_UIDid.loader = true
            state.verify_UIDid.displayMsg = false

        },
        [verificationUID.fulfilled]: (state, action) => {


            state.verify_UIDid.response = action.payload
            state.verify_UIDid.loader = false


            if (action.payload.status === "Successfull") {
                state.verify_UIDid.isValid = true
            } else {
                state.verify_UIDid.displayMsg = true
                state.verify_UIDid.IsError = true
            }
            state.verify_UIDid.loader = false

        },

        [verificationUID.rejected]: (state, action) => {
            // state.verificationPPPID.optLoader = false;
            // state.verificationPPPID.error = true;

            // console.log(action.error.message)

            state.verify_UIDid.response = action.payload
            state.verify_UIDid.isValid = null
            state.verify_UIDid.IsError = true
            state.verify_UIDid.loader = false
            state.verify_UIDid.displayMsg = true
            // state.loading = false;
            // state.error = action.error.message;
        },


        [verificationOTP.pending]: (state) => {
            state.verify_otp.loader = true
            state.verify_otp.response = {}
            state.verify_otp.isValid = false
            state.verify_otp.isError = false
                
        },
        [verificationOTP.fulfilled]: (state, action) => {
            state.verificationOTP = action.payload;
            state.ppp_details.response = action.payload
            state.verify_otp.loader = false


            if (action.payload.status === "Successfull") {
                sessionStorage.setItem("isLoggedIn", true)
                state.verify_otp.response = action.payload;
                state.verify_otp.isValid = true;

                state.ppp_details.response = action.payload;
                state.ppp_details.isValid = true;
            } else {
                sessionStorage.setItem("isLoggedIn", false)
                state.verify_otp.isValid = false;
                state.verify_otp.IsError = false;

                state.ppp_details.isValid = false;
                state.ppp_details.IsError = true;

            }

        },
        [verificationOTP.rejected]: (state, action) => {

            state.verify_otp.response = action.payload
            state.verify_otp.isError = true
            state.verify_otp.loader = false
        },


        [checkEligibilityIncome.pending]: (state) => {

            // eligibility: {
            //     respData: {},
            //     isError: null,
            //     isValid: null,
            //     message: []
            // },

            state.eligibility.respData = {}
            state.eligibility.isError = null
            state.eligibility.isValid = null
            state.eligibility.message = ""

            // state.optResp.isValid = null
            // state.loading = true;
            // state.error = null; // Reset the error state when starting the API call
        },
        [checkEligibilityIncome.fulfilled]: (state, action) => {
            // state.eligibility = action.payload
            // console.log("= action.payload",action.payload)
            state.eligibility.respData = action.payload
            state.eligibility.isValid = true

        },
        [checkEligibilityIncome.rejected]: (state, action) => {
            state.eligibility.isError = true
            // console.log(action)
            // state.eligibility.message = action?.payload?.message

            // // state.loading = false;
            // state.optResp.isValid = false
            // // state.error = action.error.message;
        }
    },
});

export const { paymentPostData,
    clearOtpLoader,
    clearOtpResp,
    clearEligibilityState,
    enableTxnFeeTable,
    clearSessionData,
    clearVerificationPPPID,
    clearVerificationUID,
    clearOTPResponse
} = verifyDetailsSlice.actions;


export default verifyDetailsSlice.reducer;