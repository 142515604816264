import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import classes from "./card.module.css"
// import { useNavigate } from 'react-router-dom';

function Card({ heading, body, footer, configCard,  }) {
    // const navigate = useNavigate();
    // console.log("configCard?.enableIcon",configCard)


    const backToScreen = () =>{
        configCard?.backHandler(configCard?.component)   
    }

    return (
        <div className={`card w-100 rounded-4`}>

            <div className="card-header p-2 d-flex">
                {configCard?.enableIcon && <div className={classes.card_h_back_button}>
                    <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => backToScreen()} />
                </div>}

                <div className={configCard?.enableIcon ? classes.card_h_heading : 'flex-fill'}>
                    {heading}
                </div>

            </div>
            <div className={`${configCard?.bodyheight} card-body`} >
                {body}
            </div>
            <div className="card-footer text-body-secondary">
                {footer}
            </div>
        </div>
    )
}

export default Card