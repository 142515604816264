import { useNavigate, useLocation } from 'react-router-dom';

export function useQueryParams() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const deleteQueryParam = (key) => {
    queryParams.delete(key);
    navigate(`${location.pathname}?${queryParams}`);
  };

  return {
    deleteQueryParam
  };
}
