import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuthSession } from '../../slice/login/loginSlice';
import { API_URL } from '../../config/config';

// import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'


function AuthButton({ authData }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // console.log(authData, csc_merchant_name)
    const { csc_merchant_name } = authData
    const logoutHandler = ()=>{
        dispatch(clearAuthSession())
        window.location.href= API_URL.csc_connect_frontend_url
    }
    return (
        <Dropdown className='mx-3'>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className="btn-sm text-bold">
                <FontAwesomeIcon icon={faUserCircle} /> {csc_merchant_name}
            </Dropdown.Toggle>

            <Dropdown.Menu className='p-0'>
                <Dropdown.Item href={false} onClick={()=>logoutHandler()}> <FontAwesomeIcon icon={faSignOut} /> Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    )
}

export default AuthButton