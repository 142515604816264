import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import MyContext from '../../../hooks/MyContext';
import { useLocation } from 'react-router-dom';
import { currentSiteLanguage } from '../../../multi-language/config';
import logohr from "../../../assest/images/logo-hr-removebg-preview.png"
import logopm from "../../../assest/images/logo-pmjay-removebg-preview.png"
import ayushLogo from "../../../assest/images/ayush-logo.svg"

// import sabpaisalogo from '../../assets/images/sabpaisalogo.png'

function PrintDocument(props) {
    const { data } = props;
    // console.log("data",data)
    const [firstCol, setFirstCol] = useState(data)

    useEffect(() => {
        if(data?.length>0){
            setFirstCol(data[0])
        }
        
    }, [data])


        // site language detecter
        const context = useContext(MyContext);
        const [siteLanguage, setSiteLanguage] = useState("en");
        const location = useLocation()
        useEffect(() => {
            setSiteLanguage(currentSiteLanguage())
        }, [location])


        // console.log(data)
    return (
        <div className='print-document' style={{ "display": "none" }} id="print_docuement">
               {data?.length>0 && data?.map((d, i) => (
            <table width="100%" cellSpacing={0} cellPadding={0} border={0} bgcolor="#fff" key={i}>
                <tbody>
                    <tr>
                        <td>
                            <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                <tbody>
                                    <tr>
                                        <td style={{ color: "#000" }}>
                                            <table width="100%" cellSpacing={0} cellPadding={0} border={0}>
                                                <tbody>
                                                    <tr>
                                                        <td width="100%">
                                                            <table
                                                                width="100%"
                                                                cellSpacing={0}
                                                                cellPadding={5}
                                                                border={0}
                                                            >
                                                                <tbody>
                                                                <tr>
                                                                            <td style={{width:"5%"}}>
                                                                                <img src={logohr} alt="Logo" style={{maxWidth: "33%"}} />
                                                                            </td>
                                                                            <td style={{width:"5%"}}>
                                                                                <img src={logopm} alt="Logo" style={{maxWidth: "33%"}} />
                                                                            </td>
                                                                            <td style={{width:"5%"}}>
                                                                                <img src={ayushLogo} alt="Logo" style={{maxWidth: "33%"}} />
                                                                            </td>
                                                                        </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                    <tr>
                                        <td height={3} style={{ fontSize: 0 }}>
                                            &nbsp;
                                        </td>
                                    </tr>
                                    <tr>

                                        <td width="100%">
                                            <table width="100%" cellPadding={8} cellSpacing={0} border={0}>
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            height={34}
                                                            colSpan={6}
                                                            align="left"
                                                            bgcolor="#f6f5f5"
                                                            style={{
                                                                fontSize: 14,
                                                                border: "1px solid #e4e4e4",
                                                                color: "#000000"
                                                            }}
                                                        >
                                                            <strong>{context[siteLanguage]["payment_receipt"]}</strong>
                                                        </th>
                                                    </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["ppp_id"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.ppp_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["mobile_no_email"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.mobile_no_email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["transaction_id"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.transaction_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["reference_number"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.client_trans_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["data_time"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.data_time}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["name"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["amount"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>₹ {d?.amount}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["payment_status"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.trans_status}</td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["payment_bank_name"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.payment_bank_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th align="left" width="22%" style={{ border: "1px solid #e4e4e4" }}>
                                                                <strong>{context[siteLanguage]["payment_mode"]}</strong>
                                                            </th>
                                                            <td width="78%" style={{ border: "1px solid #e4e4e4" }}>{d?.payment_mode}</td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br pagebreak="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        ))}
        </div>
    )
}

export default PrintDocument