import { API_URL } from "../config/config"
import { axiosSession as axios } from "../utilities/axiosInstance"


export const SaveLogs = (obj) => {
    // axios.defaults.headers.common = {
    //     "session-id":obj?.session_id
    // }
    axios.post(API_URL.log_save, obj).then(resp => {
        // console.log("d",resp)
    }).catch(err => {
        // console.log("err",err)
    })
}

