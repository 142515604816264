import uniqueId from 'uniqid';
import { v4 as uuidv4 } from 'uuid';

// generate and save session stroge 

export const SessionId = ()=>{
    const ssid = sessionStorage.getItem("ssid")
    // console.log("call ssid",ssid)
    if(ssid!=="" && ssid!==null){
        return ssid.toLocaleUpperCase()
    }else{
        const ssidGenerate = uniqueId() + '-' + uuidv4()
        sessionStorage.setItem("ssid", ssidGenerate.toLocaleUpperCase())
        return ssidGenerate.toLocaleUpperCase()
    }
    
}