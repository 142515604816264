import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../components/card/Card'
import uniqid from 'uniqid';
import { v4 as uuidv4 } from 'uuid';
// import classes from "./verify.module.css"
// import SabpaisaPaymentGateway from '../sabpaisa-pg/SabpaisaPaymentGateway';
import Navbar from '../../components/navbar/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { checkEligibilityIncome, clearEligibilityState, clearSessionData, paymentPostData } from '../../slice/verifyDetails/verifyDetailSlice';
import moment from 'moment';
import { verfiDetailServices } from '../../service/verifyDetailService';

import { sp_bank_id } from '../../config/config';
import MyContext from '../../hooks/MyContext';
import { currentSiteLanguage } from '../../multi-language/config';
// import toastConfig from '../../components/toastTypes';
import CheckEligible from './check-eligible/CheckEligible';
import { transactionDetailService } from '../../service/transactionEnqService';
import { SessionId } from '../../utilities/SessionId';
import { useTracking } from 'react-tracking';
// import { transactionDetailService } from '../../service/transactionEnqService';


function PreviewDetails() {


  const [isEligible, setIsEligible] = useState(null)
  const [checkEligibilityLoader, setCheckEligibilityLoader] = useState(false)
  const [pppMemberDetails, setPPPMemberDetails] = useState([])
  const [headPersonData, setHeadPersonData] = useState({})
  const [transAmount, setTransAmount] = useState(1500)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [paymentStatusMsg, setPaymentStatusMsg] = useState("")


  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()

  const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"))
  if (!isLoggedIn) {
    trackEvent({
      message: "User/Action/preview-details/ Not LoggedIn Redirect to home page",
      ppp_id: "",
      session_id: SessionId()
    })
    navigate("/")
  }



  // console.log("transAmount",transAmount)


  const { verify } = useSelector(state => state)
  const { ppp_details } = verify

  const pppFamilyDetailsRedux = ppp_details?.response?.result
  const pppMemberDetailsRedux = ppp_details?.response?.result?.pppMemberDetails


  if (!ppp_details?.isValid) {
    //  window.location.href = "/"
    trackEvent({
      message: "User/Action/preview-details/ Not Valid Response, Redirect to home page",
      ppp_id: "",
      session_id: SessionId()
    })
    navigate("/")
  }

  // const queryStringUrl = window.location.search;


  const searchParam = window.location.search.slice(1)
  const queryString = new URLSearchParams(searchParam?.toString());
  // const queryStringData = Object.fromEntries(queryString.entries());



  // site language detecter
  const context = useContext(MyContext);
  const [siteLanguage, setSiteLanguage] = useState("en");
  const location = useLocation()
  useEffect(() => {
    setSiteLanguage(currentSiteLanguage())
  }, [location])


  const closeModal = () => {
    // console.log("call close fn")
    // closeModalFn(null)
    trackEvent({
      message: "User/Action/preview-details/User Exit ",
      ppp_id: pppFamilyDetailsRedux?.familyId,
      session_id: SessionId()
    })
    dispatch(clearEligibilityState())
    dispatch(clearSessionData())
    navigate('/')
  }

  useEffect(() => {

    setPPPMemberDetails(pppFamilyDetailsRedux)
    const headOftheFamilyData = pppMemberDetailsRedux?.filter(item => item?.isHouseHoldOrMember === "H")

    if (headOftheFamilyData?.length > 0) {
      setHeadPersonData(headOftheFamilyData[0])
    }




    // console.log(verify?.verificationOTP?.result?.pppMemberDetails)

    // console.log(verify?.eligibility?.isValid)
    if (verify?.eligibility?.isValid) {
      setCheckEligibilityLoader(false)
      setIsEligible(true)

    }


// get amount based on the users

    const auth_ssid = sessionStorage.getItem("auth_ssid")
    let auth = "bank";
    if (auth_ssid !== null && auth_ssid !== "") {
      auth = "csc";
    }

    verfiDetailServices.getTransactionAmt(auth).then(resp => {

      setTransAmount(resp?.data?.transaction_amount)
      // console.log(resp)
    }).catch(err => console.log(err))


  }, [pppFamilyDetailsRedux, pppMemberDetailsRedux, verify])



  useEffect(() => {
    // check payment status
    if (pppFamilyDetailsRedux?.familyId !== "" && pppFamilyDetailsRedux?.familyId !== undefined && pppFamilyDetailsRedux?.familyId !== null) {
      const ObjCheckPayStatus = {
        "bank_id": sp_bank_id,
        "ppp_id": pppFamilyDetailsRedux?.familyId
      }
      transactionDetailService.checkPaymentStatus(ObjCheckPayStatus).then(resp => {
        setPaymentStatus(resp?.data?.status)
        setPaymentStatusMsg(resp?.data?.message)

      }).catch(err => {
        const errMsgTmp = err?.response?.data?.message
        // console.log(err?.response?.data?.detail)
        setPaymentStatus(true)
        setPaymentStatusMsg(errMsgTmp)
      })
    }

  }, [pppFamilyDetailsRedux])





  const checkFamilyEligibility = () => {


    setCheckEligibilityLoader(true)
    const checkEligibilityData = {
      family_income: pppMemberDetails?.familyIncome,
      is_income_verified: pppMemberDetails?.isIncomeVerified,
      bank_id: sp_bank_id
    }

    trackEvent({
      message: `User/Action/preview-details/PPP Check Eligibily-${JSON.stringify(checkEligibilityData)}`,
      ppp_id: pppFamilyDetailsRedux?.familyId,
      session_id: SessionId()
    })

    dispatch(checkEligibilityIncome(checkEligibilityData))



  }


  const cardHeading = (
    <h5 className="text-center text-primary-color m-0 p-1">{context[siteLanguage]["ppp_details"]}</h5>
  )


  const cardBody = (
    <React.Fragment>
      <div className="d-lg-flex">
        <div className="col-lg-6">
          <h5 className="m-0 mb-3">{context[siteLanguage]["ppp_id_number"]} : {pppMemberDetails?.familyId}</h5>
          <table className="table table-borderless">
            <tbody>
              {/* <tr>
                <th>{context[siteLanguage]["id"]} :</th>
                <td>{pppMemberDetails?.familyId}</td>
              </tr> */}
              <tr>
                <th>{context[siteLanguage]["family_head_name"]}:</th>
                <td>{verify?.verificationOTP?.result?.familyHeadName}</td>
                <td><hr /></td>

              </tr>
              <tr>
                <th>{context[siteLanguage]["district"]}:</th>
                <td>{siteLanguage === 'hi' ? pppMemberDetails?.familyDNameLL : pppMemberDetails?.familyDName}</td>
              </tr>
              <tr>
                <th>{context[siteLanguage]["address"]}:</th>
                <td> H No. {verify?.verificationOTP?.result?.familyHouseNumber}, Street No. {verify?.verificationOTP?.result?.familyStreetNumber}, familyLandMark {verify?.verificationOTP?.result?.familyLandMark}, {verify?.verificationOTP?.result?.familyPinCode} </td>
              </tr>
              <tr>
                <th>{context[siteLanguage]["family_income"]}:</th>
                <td>₹ {verify?.verificationOTP?.result?.familyIncome} </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* console.log() */}
        <div className="col-sm-12 col-md-12 col-lg-6 right-side-details">

          <h5 className="mb-3">{context[siteLanguage]["linked_member_info"]}</h5>
          <table className="table table-borderless text-uppercase">
            <tbody>
              {pppMemberDetails?.pppMemberDetails?.map((item, i) => (
                <tr key={uniqid()}>
                  <td>{(i + 1)}. {siteLanguage === 'hi' ? item?.fullNameLL : item?.fullName} ({item?.relationshipWithHH_name})</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )




  const cardFooter = (
    <div className="text-center">
      {/* {verify?.eligibility?.isValid ? */}

      {paymentStatus === true && <p className="text-danger">{paymentStatusMsg}</p>}


      {paymentStatus === false &&
        <button className="btn btn-sm btn-primary-color ent" onClick={() => checkFamilyEligibility()}>
          {checkEligibilityLoader ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : context[siteLanguage]["check_eligibility"]}
        </button>}

      {paymentStatus === true && <button type="button" onClick={() => closeModal()} className="btn btn-secondary btn-sm">
        {context[siteLanguage]["close"]}
      </button>}
      <p className="mt-3 ">{context[siteLanguage]["note_for_pppid_related"]}</p>
    </div>
  )


  const backButtonHandler = () => {
    navigate("/")
  }

  // queryStringData?.sabpaisaTxnId ? 
  const configCard = {
    // width: "w-100",
    enableIcon: true,
    backHandler: () => { backButtonHandler() },
  }

  const generateDateTimeString = () => {
    const formattedDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    return formattedDate;
  };


  const submitHandler = () => {
    const prefix = 'CHP';
    const uniqueId = `${prefix}${uuidv4().replace(/-/g, '').substring(0, 15)}`;
    const paymentData = {
      payerName: pppFamilyDetailsRedux?.familyHeadName,//d
      payerEmail: headPersonData?.email === "" ? "chirayu@gmail.com" : headPersonData?.email, //static
      payerMobile: headPersonData?.mobileNo,//d
      clientTxnId: uniqueId?.toUpperCase(),
      amount: transAmount,
      scheme_id: "",//static
      crid: "",//static
      uid: verify?.verificationPPPID?.respData?.uid,//d
      district_name: pppFamilyDetailsRedux?.familyDName,//d
      pppid_fid: pppFamilyDetailsRedux?.familyId,//d
      state: "Haryana", //static
      trans_init_date: generateDateTimeString(),
      tehsil_code: pppFamilyDetailsRedux?.tehsilcode,
      family_district_code: pppFamilyDetailsRedux?.familyDcodeLGD
    }

    trackEvent({
      message: `User/Action/preview-details/Submit for the payment and redirect to the payment handler page-${JSON.stringify(paymentData)}`,
      ppp_id: pppFamilyDetailsRedux?.familyId,
      session_id: SessionId()
    })
    dispatch(paymentPostData(paymentData))
    navigate(`/payment-handler/?${queryString}`);

  }
  return (
    <div className="container-fluid">
      <Navbar enableLogo={true} />

      {(isEligible !== null && paymentStatus === false) &&
        <CheckEligible eligibility={verify?.eligibility} fnSubmitHandler={submitHandler} buttonText={context[siteLanguage]["proceed_further_for_payment"]} closeModalFn={() => setIsEligible} paymentStatusResp={paymentStatus} familyId={pppFamilyDetailsRedux?.familyId} />}

      <div className="row">
        <div className="col-lg-6 justify-content-center align-items-center px-4">
          <Card heading={cardHeading} body={cardBody} footer={cardFooter} configCard={configCard} />
        </div>
      </div>
    </div>

  )
}

export default PreviewDetails