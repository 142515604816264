// import axios from "axios"
import { API_URL, sp_bank_id } from "../config/config"
import { currentSiteLanguage } from "../multi-language/config";
import { axiosSession as axios } from "../utilities/axiosInstance";

const lang = currentSiteLanguage();

const configAxios = {
    headers: {
        "Accept-Language": lang.toUpperCase()
    }
}


const verifyPPPIDService = (obj) => {
    return axios.post(API_URL.verify_ppp_id, obj, configAxios);
}

const verifyUIDIDService = (obj) => {
    return axios.post(API_URL.family_id_by_uid, obj, configAxios);
}

const verifyOtpService = (obj) => {
    // console.log("service")
    return axios.post(API_URL.verify_otp, obj, configAxios);
}

const getTransactionAmt = (param) => {
    return axios.get(`${API_URL.get_transaction_amount}?bank_id=${sp_bank_id}&amount_type=${param}`);
}


const checkEligibility = (obj) => {
    return axios.post(API_URL.check_eligibility, obj, configAxios);
}



export const verfiDetailServices = {
    verifyPPPIDService,
    verifyUIDIDService,
    verifyOtpService,
    getTransactionAmt,
    checkEligibility

}
