import React, { useContext, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import languageIcon from "../assest/images/lang-icon.svg"
import { useLocation } from 'react-router-dom';
import { currentSiteLanguage } from '../multi-language/config';
import MyContext from '../hooks/MyContext';


function DropDown({ handlerChange, siteLanguage, currentLang }) {

    const optionSelect = siteLanguage?.filter(item => item !== currentLang)
    const langName = {
        "en": "English",
        "hi": "Hindi"
    }

    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguageState, setSiteLanguageState] = useState("hi");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguageState(currentSiteLanguage())
    }, [location])


    return (

        <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className="btn-sm padding_2px">
                <img src={languageIcon} alt="logo" width={25} className="" /> {context[siteLanguageState]['select_language']}</Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href={false} onClick={() => (handlerChange(optionSelect))}>{langName[optionSelect]}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    )
}

export default DropDown