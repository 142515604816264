import axios from "axios";
import { SessionId } from "./SessionId";

export const axiosSession = axios.create({});


// Request interceptor
axiosSession.interceptors.request.use(
    config => {
        // Modify the request config before sending
        config.headers['session-id'] = SessionId();
        return config;
    },
    error => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Response interceptor
axiosSession.interceptors.response.use(
    response => {
        // Modify the response data before resolving
        return response;
    },
    error => {
        // Handle response errors
        return Promise.reject(error);
    }
);
