import React, { useEffect, useState } from 'react'
import NavbarWithLogo from './desktop/NavBarWithLogo.js';
import MoveStuffAround from '../marquee/MoveStuffAround.js';



function Navbar({ enableLogo, mobileEnable, forceDisable, disableTxnEnq, isMasquee}) {

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [logoEnableDisable, setLogoEnableDisable] = useState(enableLogo)

  useEffect(() => {
    // Function to update the state with current window width and height
    const updateViewportDimensions = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    // Event listener to update dimensions on window resize
    window.addEventListener('resize', updateViewportDimensions);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateViewportDimensions);
    };

  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    setLogoEnableDisable(enableLogo)
    if (viewportWidth <= 992 && forceDisable === false) {
      setLogoEnableDisable(true)
    } else {
      setLogoEnableDisable(enableLogo)
    }
  }, [viewportWidth, viewportHeight, enableLogo])


  const marqueeText = "The registration for eligible beneficiaries would start from 15/08/2023. This is office login."
  return (
    <React.Fragment>
      {/* <NavbarWithLogo /> */}

      {/* {logoEnableDisable===false && <NavbarWithoutLogo disableTxnEnq={disableTxnEnq} />} */}
      
      {logoEnableDisable === true && <NavbarWithLogo disableTxnEnq={disableTxnEnq} />}
    </React.Fragment>

  )
}

export default Navbar