import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDataBySession } from '../../service/loginService';

const authUserData = {};
const session_id = sessionStorage.getItem("auth_ssid");
const auth_fullname = sessionStorage.getItem("auth_fullname");
const isAuthValid = !!session_id;

const initialState = {
    authUserData,
    isAuthValid,
    session_id,
    csc_merchant_name: auth_fullname
};

export const fetchDataBySession = createAsyncThunk(
    'login/fetchDataBySession',
    async (obj, thunkAPI) => {
        try {
            const response = await getDataBySession(obj);
            return response.data;
        } catch (error) {

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            const err = { message: message, status_code: error?.response?.data?.status_code }

            return thunkAPI.rejectWithValue(err);
        }
    }


);


const loginSlice = createSlice({
    name: "loginSlice",
    initialState: initialState,
    reducers: {
        clearAuthSession: (state) => {
            state.authUserData = {}
            state.isAuthValid = false
            state.session_id = ""
            state.csc_merchant_name = ""
            sessionStorage.removeItem("auth_fullname")
            sessionStorage.removeItem("auth_ssid")
        }
    },
    extraReducers: {
        [fetchDataBySession.pending]: (state) => {
            state.authUserData = {}
            state.isAuthValid = false
        },
        [fetchDataBySession.fulfilled]: (state, action) => {
            // console.log(action)
            // console.log()
            state.authUserData = action.payload
            if (action.payload.status) {
                state.isAuthValid = true
                sessionStorage.setItem("auth_ssid", action.meta.arg.session_id)
                sessionStorage.setItem("auth_fullname", action.payload?.user?.fullname)
                state.session_id = action.meta.arg.session_id
                state.csc_merchant_name = action.payload?.user?.fullname
            } else {
                state.isAuthValid = false
            }

        },
        [fetchDataBySession.rejected]: (state) => {
            state.authUserData = {}
            state.isAuthValid = false
        },
    }
})

export const {
    clearAuthSession
} = loginSlice.actions
export default loginSlice.reducer